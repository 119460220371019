.display-inline-flex{
    display: inline-flex;
}
.year{
    font-size: 40px;
    font-weight: 700;
    border-bottom: 1px solid #6b6868;
    text-align: left;
    margin-left: 1.7rem;
    /* position: sticky !important;
    top: 90px;
    z-index: 100;
    background-color: '#f7f7f7' */
}
.year-current{
    color:red;
    font-size: 40px;
    font-weight: 700;
    border-bottom: 1px solid #6b6868;
    text-align: left;
    margin-left: 1.7rem;
    /* position: sticky !important;
    top: 90px;
    z-index: 100;
    background-color: '#f7f7f7' */
}
.my-curDay{
   color: red
}

.flex{
    display: flex;
    /*flex-wrap: wrap;*/
}
.grid{
    display: grid;
    margin-bottom: 10px;

}
.flex-month {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-bottom: 10px;

    /*Animation try*/
    /*background-color: white;*/
    /*-webkit-animation-name: example; !* Safari 4.0 - 8.0 *!*/
    /*-webkit-animation-duration: 4s; !* Safari 4.0 - 8.0 *!*/
    /*animation-name: example;*/
    /*animation-duration: 2s;*/
}
.transform {
    -webkit-transition: all 2s ease;
    -moz-transition: all 2s ease;
    -o-transition: all 2s ease;
    -ms-transition: all 2s ease;
    transition: all 1s ease-out;
}

.transform-active {
    transition: all 2s ease-out;
    transform  : scaleX(1);
    /*background-color: #45CEE0;*/
    height: 200px;
    width: 200px;
}

table{
    table-layout: fixed;
}
table td{
    text-align: center;
}
table th{
    text-align: center;
}
.weekday-shortnames{
    font-size: 10px;
    padding: 0 !important;
}
header{
    height: 63px;
}

.weekend-sticky{
    position: sticky !important; 
    top :63px; 
    background-color: #f7f7f7;
    z-index: 100; 
    padding : 0px !important;
}
.year-tab{
    position: sticky !important; 
    top :63px; 
    background-color: rgb(247, 247, 247);
    z-index: 100; 
    padding : 0px !important;

}

.day-scrollable{
    position: sticky !important;
    top: 63px;
    background-color: rgb(247, 247, 247);
    z-index: 100;

}
.month-scrollable{
    position: sticky;
    top: 113px;
    /* background-color:greenyellow; */
    background-color: rgb(247, 247, 247);
    z-index: 100;

}
.year-scrollable{
    position: sticky;
    top: 83px;
    /* background-color:greenyellow; */
    background-color: rgb(247, 247, 247);
    z-index: 99;

}
.full-height{
    height: 100%;
}
@keyframes example {
    from {background-color: white;}
    to {background-color: red;}
}
.padding {
    padding :10px;
}
.margin-bottom {
   margin-bottom: 0px;
}
.full-width{
    width: 100%;
    z-index: 1;
}
.calendar-day{
    font-size: 20px;
    padding: 0px !important;
    cursor: pointer;
}
.calendar-day-detail{
    font-size: 20px;
    border-top: 1px solid #b6b9b985;
    padding-bottom: 41px;
    padding-top: 13px;
    cursor: pointer;
}
.pointer{
    cursor: pointer;
}
.empty{
    border-top: none!important;
    
}
.month{
    font-size: 19px;
    font-weight: 700;
}
.my-month{
    font-size: 19px;
    font-weight: 550;
}
.month-current{
    color: red;
    font-size: 19px;
    font-weight: 700;
}
.red{
    background: red;
    border-radius: 30px;
    color: white;
    /* border-width: 12px; */
    border: 9px solid #fa1100;
    margin-left: -15px;
}
.red-list{
    background: red;
    border-radius: 30px;
    color: white;
}

input {
    outline: 0;
    border-width: 0 0 2px;
    border-color: blue;
    background-color: rgb(250, 250, 250);
    border-bottom: 1px solid #eeeeee !important;
}
input:focus {
    border-color: green
}
.input-event{
    border-bottom: 1px solid #f7f7f7 ;
    align-self : flex-start ;
    height: 20px;
}
.event-row{
    margin-bottom: 13px;
    margin-left: 0px;
    display: flex;
    align-items: flex-end;
    height: 30px;
}
.time{
    margin-right: 15px;
    padding: 0px !important;
    text-align: right;
    margin-bottom: 0px;
}

.agenda-date{
    background-color: #f7f7f7;
    font-size: 19px;
    color: black;
    position: sticky !important;
    top: 90px;
    z-index: 1;
}
.agenda{
    border-bottom: 1px solid grey;
}
.agenda-timings{
    border-right: 1px solid #efefef;
}
.agenda-timings .agenda-time{
    margin-bottom: 0px !important;
    color: #c8c8c8;
}
.title-location{
    background-color: white;
}
.agenda .title-location .title{
    margin-bottom: 0px !important;
}
.agenda .title-location .location{
    margin-bottom: 0px !important;
    color: #c8c8c8;
}
.scroll-weeks{
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

}
/*.scroll-weeks:first-child {*/
    /*display: none;*/
/*}*/
.scroll-weeks::-webkit-scrollbar {
    display: none;
}
.height{
    height: 50px;
}
.week-scroll {
    display: flex;
    justify-content: space-between;
}
.tdBckgrnd{
    background-color: rgb(204, 239,252);
}
