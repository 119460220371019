body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",*/
/*    monospace;*/
/*}*/

h1 {
    color: #333;
    font-size: 20px;
    -webkit-font-feature-settings: "smcp", "c2sc";
            font-feature-settings: "smcp", "c2sc";
    font-variant-caps: all-small-caps;
}
.display-inline-flex{
    display: inline-flex;
}
.year{
    font-size: 40px;
    font-weight: 700;
    border-bottom: 1px solid #6b6868;
    text-align: left;
    margin-left: 1.7rem;
    /* position: sticky !important;
    top: 90px;
    z-index: 100;
    background-color: '#f7f7f7' */
}
.year-current{
    color:red;
    font-size: 40px;
    font-weight: 700;
    border-bottom: 1px solid #6b6868;
    text-align: left;
    margin-left: 1.7rem;
    /* position: sticky !important;
    top: 90px;
    z-index: 100;
    background-color: '#f7f7f7' */
}
.my-curDay{
   color: red
}

.flex{
    display: flex;
    /*flex-wrap: wrap;*/
}
.grid{
    display: grid;
    margin-bottom: 10px;

}
.flex-month {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-bottom: 10px;

    /*Animation try*/
    /*background-color: white;*/
    /*-webkit-animation-name: example; !* Safari 4.0 - 8.0 *!*/
    /*-webkit-animation-duration: 4s; !* Safari 4.0 - 8.0 *!*/
    /*animation-name: example;*/
    /*animation-duration: 2s;*/
}
.transform {
    -webkit-transition: all 2s ease;
    -webkit-transition: all 1s ease-out;
    transition: all 1s ease-out;
}

.transform-active {
    -webkit-transition: all 2s ease-out;
    transition: all 2s ease-out;
    -webkit-transform  : scaleX(1);
            transform  : scaleX(1);
    /*background-color: #45CEE0;*/
    height: 200px;
    width: 200px;
}

table{
    table-layout: fixed;
}
table td{
    text-align: center;
}
table th{
    text-align: center;
}
.weekday-shortnames{
    font-size: 10px;
    padding: 0 !important;
}
header{
    height: 63px;
}

.weekend-sticky{
    position: -webkit-sticky !important;
    position: sticky !important; 
    top :63px; 
    background-color: #f7f7f7;
    z-index: 100; 
    padding : 0px !important;
}
.year-tab{
    position: -webkit-sticky !important;
    position: sticky !important; 
    top :63px; 
    background-color: rgb(247, 247, 247);
    z-index: 100; 
    padding : 0px !important;

}

.day-scrollable{
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 63px;
    background-color: rgb(247, 247, 247);
    z-index: 100;

}
.month-scrollable{
    position: -webkit-sticky;
    position: sticky;
    top: 113px;
    /* background-color:greenyellow; */
    background-color: rgb(247, 247, 247);
    z-index: 100;

}
.year-scrollable{
    position: -webkit-sticky;
    position: sticky;
    top: 83px;
    /* background-color:greenyellow; */
    background-color: rgb(247, 247, 247);
    z-index: 99;

}
.full-height{
    height: 100%;
}
@-webkit-keyframes example {
    from {background-color: white;}
    to {background-color: red;}
}
@keyframes example {
    from {background-color: white;}
    to {background-color: red;}
}
.padding {
    padding :10px;
}
.margin-bottom {
   margin-bottom: 0px;
}
.full-width{
    width: 100%;
    z-index: 1;
}
.calendar-day{
    font-size: 20px;
    padding: 0px !important;
    cursor: pointer;
}
.calendar-day-detail{
    font-size: 20px;
    border-top: 1px solid #b6b9b985;
    padding-bottom: 41px;
    padding-top: 13px;
    cursor: pointer;
}
.pointer{
    cursor: pointer;
}
.empty{
    border-top: none!important;
    
}
.month{
    font-size: 19px;
    font-weight: 700;
}
.my-month{
    font-size: 19px;
    font-weight: 550;
}
.month-current{
    color: red;
    font-size: 19px;
    font-weight: 700;
}
.red{
    background: red;
    border-radius: 30px;
    color: white;
    /* border-width: 12px; */
    border: 9px solid #fa1100;
    margin-left: -15px;
}
.red-list{
    background: red;
    border-radius: 30px;
    color: white;
}

input {
    outline: 0;
    border-width: 0 0 2px;
    border-color: blue;
    background-color: rgb(250, 250, 250);
    border-bottom: 1px solid #eeeeee !important;
}
input:focus {
    border-color: green
}
.input-event{
    border-bottom: 1px solid #f7f7f7 ;
    align-self : flex-start ;
    height: 20px;
}
.event-row{
    margin-bottom: 13px;
    margin-left: 0px;
    display: flex;
    align-items: flex-end;
    height: 30px;
}
.time{
    margin-right: 15px;
    padding: 0px !important;
    text-align: right;
    margin-bottom: 0px;
}

.agenda-date{
    background-color: #f7f7f7;
    font-size: 19px;
    color: black;
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 90px;
    z-index: 1;
}
.agenda{
    border-bottom: 1px solid grey;
}
.agenda-timings{
    border-right: 1px solid #efefef;
}
.agenda-timings .agenda-time{
    margin-bottom: 0px !important;
    color: #c8c8c8;
}
.title-location{
    background-color: white;
}
.agenda .title-location .title{
    margin-bottom: 0px !important;
}
.agenda .title-location .location{
    margin-bottom: 0px !important;
    color: #c8c8c8;
}
.scroll-weeks{
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

}
/*.scroll-weeks:first-child {*/
    /*display: none;*/
/*}*/
.scroll-weeks::-webkit-scrollbar {
    display: none;
}
.height{
    height: 50px;
}
.week-scroll {
    display: flex;
    justify-content: space-between;
}
.tdBckgrnd{
    background-color: rgb(204, 239,252);
}

/* Fix to align left auth components */
#authContainer div div {
    text-align: left;
    margin-top: 0;
    margin: 0;
}

div.markdown div * {
 -webkit-animation: none 0s ease 0s 1 normal none running;
         animation: none 0s ease 0s 1 normal none running;
 -webkit-backface-visibility: visible;
         backface-visibility: visible;
 background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
 border: medium none currentColor;
 border-collapse: separate;
 -webkit-border-image: none;
         border-image: none;
 border-radius: 0;
 border-spacing: 0;
 bottom: auto;
 box-shadow: none;
 box-sizing: content-box;
 caption-side: top;
 clear: none;
 clip: auto;
 color: #000;
 -webkit-columns: auto;
    -moz-columns: auto;
         columns: auto;
 -webkit-column-count: auto;
    -moz-column-count: auto;
         column-count: auto;
 -webkit-column-fill: balance;
    -moz-column-fill: balance;
         column-fill: balance;
 grid-column-gap: normal;
 -webkit-column-gap: normal;
    -moz-column-gap: normal;
         column-gap: normal;
 -webkit-column-rule: medium none currentColor;
    -moz-column-rule: medium none currentColor;
         column-rule: medium none currentColor;
 -webkit-column-span: 1;
    -moz-column-span: 1;
         column-span: 1;
 -webkit-column-width: auto;
    -moz-column-width: auto;
         column-width: auto;
 content: normal;
 counter-increment: none;
 counter-reset: none;
 cursor: auto;
 direction: ltr;
 display: inline;
 empty-cells: show;
 float: none;
 font-family: serif;
 font-size: medium;
 font-style: normal;
 -webkit-font-feature-settings: normal;
         font-feature-settings: normal;
 font-variant: normal;
 font-weight: normal;
 font-stretch: normal;
 line-height: normal;
 height: auto;
 -webkit-hyphens: none;
     -ms-hyphens: none;
         hyphens: none;
 left: auto;
 letter-spacing: normal;
 list-style: disc outside none;
 margin: 0;
 max-height: none;
 max-width: none;
 min-height: 0;
 min-width: 0;
 opacity: 1;
 orphans: 2;
 outline: medium none invert;
 overflow: visible;
 overflow-x: visible;
 overflow-y: visible;
 padding: 0;
 page-break-after: auto;
 page-break-before: auto;
 page-break-inside: auto;
 -webkit-perspective: none;
         perspective: none;
 -webkit-perspective-origin: 50% 50%;
         perspective-origin: 50% 50%;
 position: static;
 right: auto;
 -moz-tab-size: 8;
      tab-size: 8;
 table-layout: auto;
 text-align: left;
 -moz-text-align-last: auto;
      text-align-last: auto;
 text-decoration: none;
 text-indent: 0;
 text-shadow: none;
 text-transform: none;
 top: auto;
 -webkit-transform: none;
         transform: none;
 -webkit-transform-origin: 50% 50% 0;
         transform-origin: 50% 50% 0;
 -webkit-transform-style: flat;
         transform-style: flat;
 -webkit-transition: none 0s ease 0s;
 transition: none 0s ease 0s;
 unicode-bidi: normal;
 vertical-align: baseline;
 visibility: visible;
 white-space: normal;
 widows: 2;
 width: auto;
 word-spacing: normal;
 z-index: auto;
 all: initial;
}
div.markdown {
  font-size: 10px;
   -webkit-animation: none 0s ease 0s 1 normal none running;
           animation: none 0s ease 0s 1 normal none running;
   -webkit-backface-visibility: visible;
           backface-visibility: visible;
   background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
   border: medium none currentColor;
   border-collapse: separate;
   -webkit-border-image: none;
           border-image: none;
   border-radius: 0;
   border-spacing: 0;
   bottom: auto;
   box-shadow: none;
   box-sizing: content-box;
   caption-side: top;
   clear: none;
   clip: auto;
   color: #000;
   -webkit-columns: auto;
      -moz-columns: auto;
           columns: auto;
   -webkit-column-count: auto;
      -moz-column-count: auto;
           column-count: auto;
   -webkit-column-fill: balance;
      -moz-column-fill: balance;
           column-fill: balance;
   grid-column-gap: normal;
   -webkit-column-gap: normal;
      -moz-column-gap: normal;
           column-gap: normal;
   -webkit-column-rule: medium none currentColor;
      -moz-column-rule: medium none currentColor;
           column-rule: medium none currentColor;
   -webkit-column-span: 1;
      -moz-column-span: 1;
           column-span: 1;
   -webkit-column-width: auto;
      -moz-column-width: auto;
           column-width: auto;
   content: normal;
   counter-increment: none;
   counter-reset: none;
   cursor: auto;
   direction: ltr;
   display: inline;
   empty-cells: show;
   float: none;
   font-family: serif;
   font-size: medium;
   font-style: normal;
   -webkit-font-feature-settings: normal;
           font-feature-settings: normal;
   font-variant: normal;
   font-weight: normal;
   font-stretch: normal;
   line-height: normal;
   height: auto;
   -webkit-hyphens: none;
       -ms-hyphens: none;
           hyphens: none;
   left: auto;
   letter-spacing: normal;
   list-style: disc outside none;
   margin: 0;
   max-height: none;
   max-width: none;
   min-height: 0;
   min-width: 0;
   opacity: 1;
   orphans: 2;
   outline: medium none invert;
   overflow: visible;
   overflow-x: visible;
   overflow-y: visible;
   padding: 0;
   page-break-after: auto;
   page-break-before: auto;
   page-break-inside: auto;
   -webkit-perspective: none;
           perspective: none;
   -webkit-perspective-origin: 50% 50%;
           perspective-origin: 50% 50%;
   position: static;
   right: auto;
   -moz-tab-size: 8;
        tab-size: 8;
   table-layout: auto;
   text-align: left;
   -moz-text-align-last: auto;
        text-align-last: auto;
   text-decoration: none;
   text-indent: 0;
   text-shadow: none;
   text-transform: none;
   top: auto;
   -webkit-transform: none;
           transform: none;
   -webkit-transform-origin: 50% 50% 0;
           transform-origin: 50% 50% 0;
   -webkit-transform-style: flat;
           transform-style: flat;
   -webkit-transition: none 0s ease 0s;
   transition: none 0s ease 0s;
   unicode-bidi: normal;
   vertical-align: baseline;
   visibility: visible;
   white-space: normal;
   widows: 2;
   width: auto;
   word-spacing: normal;
   z-index: auto;
   all: initial;
}
div.markdown div h1, div.markdown div h2, div.markdown div h3, div.markdown div h4, div.markdown div h5, div.markdown div h6 {
  font-weight: 700;
  line-height: 1.7;
  cursor: text;
  position: relative;
  margin: 0 0 15px 0;
  padding: 0; 
  display: block;
  }

div.markdown div h1 {
  font-size: 1.5em;
  border-bottom: 1px solid #ddd; }

div.markdown div h2 {
  font-size: 1.4em;
  border-bottom: 1px solid #eee; }

div.markdown div h3 {
  font-size: 1.3em; }

div.markdown div h4 {
  font-size: 1.2em; }

div.markdown div h5 {
  font-size: 1em; }

div.markdown div h6 {
  color: #777;
  font-size: 1em; }

div.markdown div p, div.markdown div blockquote, div.markdown div table, div.markdown div pre {
  margin: 15px 0; 
}

div.markdown div ul, div.markdown div ol {
  padding-left: 30px; 
  display: block;
}

div.markdown div ol li, div.markdown div ul li {
    display: list-item;
}
div.markdown div ol li:first-of-type, div.markdown div ul li:first-of-type {
    margin-top: 0px; 
}
some {
  margin: 0;
  padding: 0
}



