/* Fix to align left auth components */
#authContainer div div {
    text-align: left;
    margin-top: 0;
    margin: 0;
}

div.markdown div * {
 all: initial;
}
div.markdown {
  font-size: 10px;
   all: initial;
}
div.markdown div h1, div.markdown div h2, div.markdown div h3, div.markdown div h4, div.markdown div h5, div.markdown div h6 {
  font-weight: 700;
  line-height: 1.7;
  cursor: text;
  position: relative;
  margin: 0 0 15px 0;
  padding: 0; 
  display: block;
  }

div.markdown div h1 {
  font-size: 1.5em;
  border-bottom: 1px solid #ddd; }

div.markdown div h2 {
  font-size: 1.4em;
  border-bottom: 1px solid #eee; }

div.markdown div h3 {
  font-size: 1.3em; }

div.markdown div h4 {
  font-size: 1.2em; }

div.markdown div h5 {
  font-size: 1em; }

div.markdown div h6 {
  color: #777;
  font-size: 1em; }

div.markdown div p, div.markdown div blockquote, div.markdown div table, div.markdown div pre {
  margin: 15px 0; 
}

div.markdown div ul, div.markdown div ol {
  padding-left: 30px; 
  display: block;
}

div.markdown div ol li, div.markdown div ul li {
    display: list-item;
}
div.markdown div ol li:first-of-type, div.markdown div ul li:first-of-type {
    margin-top: 0px; 
}